export const RestaurantImageMapping: { [key: string]: string } = {
    1: 'Kizuki - Bellevue.png',
    2: 'Kizuki - Capitol Hill.png',
    3: 'Kizuki - Northgate.png',
    4: 'Kizuki - Southcenter.png',
    5: 'Swish Swish.png',
    6: 'Kizuki - Bellevue square.png',
    7: 'Kizuki - West Seattle.png',
    // 8: 'Kizuki - Beaverton.png',
    9: 'Kizuki - Tacoma Mall.png',
};