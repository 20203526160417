import AWSAppSyncClient from 'aws-appsync';
import { AUTH_TYPE } from 'aws-appsync-auth-link';
import { Auth } from 'aws-amplify';

const client = new AWSAppSyncClient({
  url: 'https://reilujx56jdw7ls5hfckpqek2y.appsync-api.us-west-2.amazonaws.com/graphql',
  region: 'us-west-2',
  auth: {
    type: AUTH_TYPE.API_KEY,
    // TODO Well-known key expiring in 2021. This is just to grant access to the public API
    apiKey: 'da2-lwofvqmxfjbyzh7pkywpy54pda',
  },
  disableOffline: true,
});

export default client;
