import React from "react";
import client from '../store/Client';
import { createTicket } from "../store/Request";
//import { getRestaurant } from "../Request";
import { getAllRestaurants } from "../store/Request";
import { RouteComponentProps } from "react-router-dom";
import PartySizeSelector from './PartySizeSelector';
import SpecialRequests from './SpecialRequests';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./JoinWaitlist.css"
import { RestaurantImageMapping } from '../utility';
import { Spinner } from "./Spinner";

interface Restaurant {
  restaurant: string;
  state: string;
  name: string;
  address: string;
  webCheckInState: string;
}

interface JoinWaitlistProps extends RouteComponentProps {

}

export default class JoinWaitlist extends React.Component<JoinWaitlistProps> {
  public state = {
    ticketNumber: '',
    partySize: 2,
    name: '',
    phone: undefined,
    restaurant: {
      name: "",
      address: "",
    } as Restaurant,
    restaurantId: '',
    partyPreference: {
      barSeatSelected: false,
      underageSelected: false,
      wheelchairSelected: false,
      highChairNumber: 0,
      boosterNumber: 0
    }
  };

  async componentDidMount()
  {
    console.log("component did mount")
    console.log(this.props.location);
    let restaurantId = this.props.location.pathname.split("/")[2]
    console.log(restaurantId)

    // TODO: we do want get the particular restaurant 
    // let restaurant = await getRestaurant(client, restaurantId)
    let restaurants: any = await getAllRestaurants(client)
    let restaurant = restaurants.filter((restaurant: Restaurant) => restaurant.restaurant === restaurantId );
    
    if (restaurant.length === 1) {
      console.log(restaurant[0])
      this.setState(
        { restaurant: restaurant[0],
          restaurantId: restaurantId })
    }
  }

  createTicket = async () => {
    console.log(this.state)
    try {
      // create a ticket
      const ticket: any = await createTicket(
        client,
        (this.props.match.params as any).restaurantId,
        this.state.partySize,
        getEstimatedWaitTime(this.state.partySize),
        this.state.name,
        this.state.phone,
        this.state.partyPreference.highChairNumber,
        this.state.partyPreference.wheelchairSelected,
        this.state.partyPreference.underageSelected,
        this.state.partyPreference.barSeatSelected,
        this.state.partyPreference.boosterNumber);
      
      console.log("back")
      this.setState({ ticketNumber: ticket.ticket });
      this.props.history.push(`/confirm/${(this.props.match.params as any).restaurantId}/${ticket.id}`);
    }
    catch (error) {
      console.log('error creating ticket');
      console.log(error);
    }
  }

  createTestTicket = () => {
    // createTicket(client, this.state.restaurantId, Math.floor(Math.random() * 10), 1, undefined, 5035679740, 0, false, false, false, 0 )
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
    
    let value: string | boolean = e.target.value;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }
    console.log({ [property]: value })
    this.setState({ [property]: value });
  }

  onPhoneNumChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
    
    let value: string | boolean = e.target.value;
    console.log("This is my phone number " + value)
    
    console.log({ [property]: value })
    this.setState({ [property]: value });
  }

  public render() {
    if (!this.state.restaurant.restaurant) {
      return <Spinner height={400} />;
    }
    if (this.state.restaurant && this.state.restaurant.webCheckInState === 'paused') {
      return <div style={{ width: '100%', height: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px'}}>
        <div className="RestName">Waitlist not available</div>
        <div className="address">This restaurant is not accepting online reservations</div>
        <img style={ { width: '200px', borderRadius: '5px', marginTop: '10px', marginBottom: '10px' } } src={process.env.PUBLIC_URL + `/assets/${RestaurantImageMapping[this.state.restaurantId]}`} />
        <div className="Title">{this.state.restaurant.name}</div>
        <div>{this.state.restaurant.address}</div>
      </div>
    }

    return <div id = "main">
      <div id = "left">
        <div id = "locationinfo">
          <p className="RestName">{this.state.restaurant.name}</p>
          <p className="address">{this.state.restaurant.address}</p>
          <div id = "map" style = {{height: "100%", width: "100%"}}>
            <img style={ { width: '100%', height: '100%', borderRadius: '5px' } } src={process.env.PUBLIC_URL + `/assets/${RestaurantImageMapping[this.state.restaurantId]}`} />
          </div>
        </div>
      </div>

     <div id="right">
      <p id="subheader"> Select your party size</p>
      <PartySizeSelector SelectionHandler={(size: any) => {
        let x: any = { target: { value: size } };
        this.onChange(x, 'partySize')
      }} value={this.state.partySize} />
      
      <p id="subheader"> Your information </p>
      <div id="textinput" style = {{justifyContent: "left"}}> 
        <input type="text"  placeholder="Name..." id="textbox" onChange={(e) => this.onChange(e, 'name')}></input>
        <input type="number" placeholder="Mobile Number..." id="numberInput" max={9999999999} onChange={(e) => this.onPhoneNumChange(e, 'phone')}></input>
      </div>

      <p id="subheader"> Requests </p>

      <SpecialRequests SelectionHandler={(partyPreference: any) => {
        let val: any = { target: { value: partyPreference } };
        this.onChange(val, 'partyPreference')
      }} />

      <p id="TOS"> You will receive texts about your visit. By cotinuing, you agree to CakeWalk's Terms of Service (link this) and Privacy Policy (and this)</p>

      <button id="createticket" onClick={this.createTicket} className="btn long-btn">GET A TICKET NUMBER</button>

      </div>

      </div>
  }
}

function getEstimatedWaitTime(partySize: number) {
  return 1;
}
