import React from 'react';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import "./PartySizeSelector.css"

interface PartySizeSelectorProps {
	SelectionHandler: (size: number) => void;
	value: number;
}

// radio style selector for party size. Provide onPartySizeSelected(number) 
// function to props which will be called when selection is changed
class PartySizeSelector extends React.Component<PartySizeSelectorProps> {
	render() {
		return (
			<ButtonToolbar style={{ justifyContent: "left", padding: "1px 20px 30px" }}>
				<ToggleButtonGroup id="toggleButtonGroup" type="radio" name="options" defaultValue={this.props.value} onChange={(e: number) => this.props.SelectionHandler(e)}>
					<ToggleButton id="radiostyle" value={1}>1</ToggleButton>
					<ToggleButton id="radiostyle" value={2}>2</ToggleButton>
					<ToggleButton id="radiostyle" value={3}>3</ToggleButton>
					<ToggleButton id="radiostyle" value={4}>4</ToggleButton>
					<ToggleButton id="radiostyle" value={5}>5</ToggleButton>
					<ToggleButton id="radiostyle" value={6}>6</ToggleButton>
					<ToggleButton id="radiostyle" value={7}>7+</ToggleButton>
				</ToggleButtonGroup>
			</ButtonToolbar>
		
		);
	}
}

export default PartySizeSelector;