import React from "react";
import '../App.css';
import './RestaurantList.css';
import client from '../store/Client';
import { getAllRestaurants } from '../store/Request';
import { Link } from "react-router-dom";
import { Spinner } from './Spinner'; 
import { RestaurantImageMapping } from '../utility';
// import kizukiLogo from '../../kizukiq-customer-rn/assets/icons/smalllogo.png';

interface Restaurant {
  restaurant: string;
  state: string;
  name: string;
  address: string;
  waitTime: number;
  partiesInLine: number;
}
        
class RestaurantList extends React.Component {
  public state = {
    restaurants: [] as Restaurant[],
    loading: true,
  };

  async componentDidMount()
  {
    let restaurants: any = await getAllRestaurants(client);
  
    restaurants = restaurants.filter((restaurant: Restaurant) => 
      restaurant.state === 'running' && restaurant.restaurant !== "0" && (Number(restaurant.restaurant) > 14 || Number(restaurant.restaurant) < 10)
      );
    this.setState({ restaurants, loading: false });
    // console.log(restaurants);
  }

  render()
  {
    return (
      <div className="RestaurantOuter">
        <div className="RestaurantInner">
          <div className="Heading">Available Restaurants</div>
          <div className="RestaurantListContainer">
            {this.state.loading ? <Spinner height={400} /> : null}
            {this.state.restaurants.map(restaurant => {
              return <Link to={`/join/${restaurant.restaurant}`} style={{ textDecoration: 'none' }}>
                <div className="RestaurantBox">
                  <div className="ImageBox">
                    <img style={ { width: '100%', height: '100%', borderRadius: '5px' } } src={process.env.PUBLIC_URL + `/assets/${RestaurantImageMapping[restaurant.restaurant]}`} />
                  </div>
                  <div className="DataBox">
                    <div className="Title">{restaurant.name || restaurant.restaurant}</div>
                    <div className="InfoText">{restaurant.address}</div>
                    <div className="InfoBox">
                      <span className="WaitTime">{Boolean(restaurant.waitTime) ? `${restaurant.waitTime} min` : 'No wait'}</span>
                      { Boolean(restaurant.partiesInLine) &&
                        <React.Fragment>
                        <span style={ { margin: '0px 5px' }}>•</span>
                        <span className="PartyCount">{restaurant.partiesInLine} {restaurant.partiesInLine === 1 ? 'party' : 'parties'} in line</span>
                        </React.Fragment>
                      }
                    </div>
                  </div>
                </div>
              </Link>;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default RestaurantList;
