import gql from 'graphql-tag';

export const createdTicketSub = function (client: any, restaurantId: any, createdTicketHandler: any) {
  console.log('createdTicketSub');
  const createdTicketSub = gql`
    subscription createTicketSub($restaurant: String!) {
      createTicket(restaurant: $restaurant) {
        restaurant
        id
        ticket
        ticketCount
        creationTime
        lastUpdatedTime
        party {
          name
          phone
          size
          preferences {
            bar
            handicap
            highChairs
            underage
            booster
          }
          estimatedWaitTime
          allPartiesPresent
        }
        currentState
        stateHistory {
          state
          timestamp
        }
        currentMessagingState {
          state
          timestamp
        }
        messagingHistory {
          state
          type
          timestamp
          error
        }
      }
    }
  `;
  const createdTicketSubVariables = {
    restaurant: restaurantId,
  };

  const observable = client.subscribe({
    query: createdTicketSub,
    variables: createdTicketSubVariables,
    // errorPolicy: 'none',
    // fetchPolicy: 'network-only'
  });

  const subscription = observable.subscribe({
    next: createdTicketHandler,
    complete: console.log,
    error: console.log,
  });
  return new Promise((resolve, reject) => {
    resolve(subscription);
  });
};

export const updatedTicketSub = function (client: any, restaurantId: any, updatedTicketHandler: any) {
  console.log('updatedTicketSub');
  const updatedTicketSub = gql`
    subscription updateQueueItemStateSub($restaurant: String!) {
      updateQueueItemState(restaurant: $restaurant) {
        restaurant
        id
        ticket
        ticketCount
        creationTime
        lastUpdatedTime
        party {
          name
          phone
          size
          preferences {
            bar
            handicap
            highChairs
            underage
            booster
          }
          estimatedWaitTime
          allPartiesPresent
        }
        currentState
        stateHistory {
          state
          timestamp
        }
        currentMessagingState {
          state
          timestamp
        }
        messagingHistory {
          state
          type
          timestamp
          error
        }
      }
    }
  `;
  const updatedTicketSubVariables = {
    restaurant: restaurantId,
  };

  const observable = client.subscribe({
    query: updatedTicketSub,
    variables: updatedTicketSubVariables,
    // errorPolicy: 'none',
    // fetchPolicy: 'network-only'
  });

  const subscription = observable.subscribe({
    next: updatedTicketHandler,
    complete: console.log,
    error: console.log,
  });
  return new Promise((resolve, reject) => {
    resolve(subscription);
  });
};

export const setAppStateSub = function (
  client: any,
  restaurantId: any,
  updatedAppStateHandler: any,
  updatedAppStateError: any,
) {
  console.log('setAppStateSub');
  const setAppStateSub = gql`
    subscription setAppStateSub($restaurant: String!) {
      setAppState(restaurant: $restaurant) {
        restaurant
        state
      }
    }
  `;
  const updatedAppStateSubVariables = {
    restaurant: restaurantId,
  };

  const observable = client.subscribe({
    query: setAppStateSub,
    variables: updatedAppStateSubVariables,
  });

  const subscription = observable.subscribe({
    next: updatedAppStateHandler,
    complete: console.log,
    error: updatedAppStateError,
  });
  return new Promise((resolve, reject) => {
    resolve(subscription);
  });
};

export const getAppState = function (client: any, restaurantId: any) {
  return new Promise((resolve, reject) => {
    console.log('Getting app state');
    const getAppStateQuery = gql`
      query getAppState($restaurant: String!) {
        getAppState(restaurant: $restaurant) {
          restaurant
          state
        }
      }
    `;
    const getAppStateVariables = {
      restaurant: restaurantId,
    };

    client
      .query({
        query: getAppStateQuery,
        variables: getAppStateVariables,
        errorPolicy: 'none',
        fetchPolicy: 'network-only',
      })
      .then((response: any) => {
        const appState = response.data.getAppState;
        console.log(appState);
        resolve(appState);
      })
      .catch(reject);
  });
};

export const partyUpdatedSub = function (client: any, restaurantId: any, partyUpdateHandler: any) {
  console.log('partyUpdatedSub');
  const partyUpdatedSub = gql`
    subscription partyUpdateSub($restaurant: String!) {
      partyUpdated(restaurant: $restaurant) {
        restaurant
        id
        ticket
        ticketCount
        creationTime
        lastUpdatedTime
        party {
          name
          phone
          size
          preferences {
            bar
            handicap
            highChairs
            underage
            booster
          }
          estimatedWaitTime
          allPartiesPresent
        }
        currentState
        stateHistory {
          state
          timestamp
        }
        currentMessagingState {
          state
          timestamp
        }
        messagingHistory {
          state
          type
          timestamp
          error
        }
      }
    }
  `;
  const partyUpdateSubVariables = {
    restaurant: restaurantId,
  };

  const observable = client.subscribe({
    query: partyUpdatedSub,
    variables: partyUpdateSubVariables,
  });

  const subscription = observable.subscribe({
    next: partyUpdateHandler,
    complete: console.log,
    error: console.log,
  });
  return new Promise((resolve, reject) => {
    resolve(subscription);
  });
};

export const getTicket = function (client: any, ticketId: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const getTicketQuery = gql`
      query getTicket($id: String!) {
        getTicket(id: $id) {
          restaurant
          id
          ticket
          ticketCount
          creationTime
          lastUpdatedTime
          party {
            name
            phone
            size
            preferences {
              bar
              handicap
              highChairs
              underage
              booster
            }
            estimatedWaitTime
            allPartiesPresent
          }
          currentState
        }
      }
    `;
    const getTicketVariables = {
      id: ticketId,
    };

    client
      .query({
        query: getTicketQuery,
        variables: getTicketVariables,
        errorPolicy: 'none',
        fetchPolicy: 'network-only',
      })
      .then((data: any) => {
        const tickets = data.data.getTicket;
        resolve(tickets);
      })
      .catch(reject);
  });
};

export const getAllTickets = function (client: any, restaurantId: any, view = ''): Promise<any> {
  return new Promise((resolve, reject) => {
    const getAllTicketsQuery = gql`
      query getAllTickets($restaurant: String!, $creationTime: String!, $view: String) {
        getAllTickets(restaurant: $restaurant, creationTime: $creationTime, view: $view) {
          restaurant
          id
          ticket
          ticketCount
          creationTime
          lastUpdatedTime
          party {
            name
            size
            preferences {
              bar
              handicap
              highChairs
              underage
              booster
            }
            estimatedWaitTime
            allPartiesPresent
          }
          currentState
          stateHistory {
            state
            timestamp
          }
          currentMessagingState {
            state
            timestamp
          }
          messagingHistory {
            state
            type
            timestamp
            error
          }
        }
      }
    `;
    var creationTime = Date.now() - 1000 * 60 * 60 * 4;
    const getAllTicketsVariables = {
      creationTime,
      restaurant: restaurantId,
      view,
    };

    client
      .query({
        query: getAllTicketsQuery,
        variables: getAllTicketsVariables,
        errorPolicy: 'none',
        fetchPolicy: 'network-only',
      })
      .then((data: any) => {
        const tickets = data.data.getAllTickets;
        resolve(tickets);
      })
      .catch(reject);
  });
};

export const createTicket = function (client: any, restaurantId: number, partySize: number,  estimatedWaitTime: number, name?: string, phoneNumber?: number, isHighChair?: number, isWheelChair?: boolean, isUnderage?: boolean, isBarSeatOk?: boolean, isBooster?: number, source = 'web' )
{
  return new Promise((resolve, reject) => {
    console.log('Creating Ticket');
    const createTicketMutation = gql`
      mutation createTicket($restaurant: String!, $id: ID!, $party: PartyInput!, $source: String) {
        createTicket(restaurant: $restaurant, id: $id, party: $party, source: $source) {
          restaurant
          id
          source
          ticket
          ticketCount
          creationTime
          lastUpdatedTime
          party {
            name
            phone
            size
            preferences {
              bar
              handicap
              highChairs
              underage
              booster
            }
            estimatedWaitTime
            allPartiesPresent
          }
          currentState
          stateHistory {
            state
            timestamp
          }
          currentMessagingState {
            state
            timestamp
          }
          messagingHistory {
            state
            type
            timestamp
            error
          }
        }
      }
    `;
    const createTicketVariables = {
      restaurant: restaurantId,
      id: guid(),
      source: source,
      party: {
        size: partySize,
        name: name,
        phone: phoneNumber,
        preferences: {
          highChairs: isHighChair,
          handicap: isWheelChair,
          underage: isUnderage,
          bar: isBarSeatOk,
          booster: isBooster,
        },
        estimatedWaitTime: estimatedWaitTime,
      },
    };
    console.log(createTicketVariables)
    
    client
      .mutate({
        mutation: createTicketMutation,
        variables: createTicketVariables,
        errorPolicy: 'none',
        fetchPolicy: 'no-cache',
      })
      .then((data: any) => {
        console.log(data);
        console.log('create Ticket successful');
        resolve(data.data.createTicket);
      })
      .catch(reject);
  });
};

export const updateParty = function (client: any, rid: any, id: any, partyObj: any) {
  return new Promise((resolve, reject) => {
    console.log('Updating Party');
    const UpdatePartyMutation = gql`
      mutation updateParty( $restaurant: String!, $id: ID!, $party: PartyInput!) {
        updateParty(restaurant: $restaurant, id: $id, party: $party) {
          restaurant
          id
          ticket
          ticketCount
          creationTime
          lastUpdatedTime
          party {
            name
            phone
            size
            preferences {
              bar
              handicap
              highChairs
              underage
              booster
            }
            estimatedWaitTime
            allPartiesPresent
          }
          currentState
          stateHistory {
            state
            timestamp
          }
          currentMessagingState {
            state
            timestamp
          }
          messagingHistory {
            state
            timestamp
          }
        }
      }
    `;
    const UpdatePartyVariables = {
      restaurant: rid,
      id: id,
      party: partyObj
    };

    client
      .mutate({
        mutation: UpdatePartyMutation,
        variables: UpdatePartyVariables,
        errorPolicy: 'none',
        fetchPolicy: 'no-cache',
      })
      .then((data: any) => {
        console.log('I just mutated a party in a ticket!');
        console.log(data);
        resolve(data.data.createTicket);
      })
      .catch(reject);
  });
};

export const updateTicket = function (client: any, id: any, restaurant: any, newState: any) {
  return new Promise((resolve, reject) => {
    console.log('Updating Ticket State');
    const updateTicketMutation = gql`
      mutation updateQueueItemState($restaurant: String!, $id: ID!, $newState: String!) {
        updateQueueItemState(restaurant: $restaurant, id: $id, newState: $newState) {
          restaurant
          id
          ticket
          ticketCount
          creationTime
          lastUpdatedTime
          party {
            name
            phone
            size
            preferences {
              bar
              handicap
              highChairs
              underage
              booster
            }
            estimatedWaitTime
            allPartiesPresent
          }
          currentState
          stateHistory {
            state
            timestamp
          }
          currentMessagingState {
            state
            timestamp
          }
          messagingHistory {
            state
            type
            timestamp
            error
          }
        }
      }
    `;
    const updateTicketQueryVariables = {
      restaurant,
      id,
      newState,
    };

    client
      .mutate({
        mutation: updateTicketMutation,
        variables: updateTicketQueryVariables,
        errorPolicy: 'none',
        fetchPolicy: 'no-cache',
      })
      .then((data: any) => {
        console.log(data);
        console.log('update Ticket successful');
        const ticket = data.data.updateTicket;
        console.log(ticket);
        resolve(ticket);
      })
      .catch(reject);
  });
};

export const getAllRestaurants = function (client: any) {
    return new Promise((resolve, reject) => {
      console.log('Getting all restaurants');
      client
        .query({
          query: gql`
            query getAllRestaurants
            {
                getAllRestaurants
                {
                    restaurant
                    state
                    name
                    address
                    waitTime
                    partiesInLine
                    webCheckInState
                }
            }
        `,
          errorPolicy: 'none',
          fetchPolicy: 'no-cache',
        })
        .then((response: any) => {
          const appState = response.data.getAllRestaurants;
          resolve(appState);
        })
        .catch(reject);
    });
};

export const getWaitTimeForRestaurant = function (client: any, restaurant: string, partySize: number, queueTime?: string) {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          query getWaitTimeForRestaurant($restaurant: String, $partySize: Int, $queueTime: String)
          {
              getWaitTimeForRestaurant(restaurant: $restaurant, partySize: $partySize, queueTime: $queueTime)
              {
                waitTime
              }
          }
      `,
        variables: { restaurant, queueTime, partySize },
        errorPolicy: 'none',
        fetchPolicy: 'no-cache',
      })
      .then((response: any) => {
        const appState = response.data.getWaitTimeForRestaurant;
        resolve(appState);
      })
      .catch(reject);
  });
};

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}
