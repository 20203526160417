import React from 'react';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import Image from 'react-bootstrap/Image'
import "./SpecialRequests.css"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

interface SpecialRequestsProps {
    SelectionHandler: (partyPreference: any) => void
}

class SpecialRequests extends React.Component<SpecialRequestsProps> {
    public state = {
        barSeatSelected: false,
        underageSelected: false,
        wheelchairSelected: false,
        highChairNumber: 0,
        showHighChairModal: false,
        boosterNumber: 0,
        showBoosterModal: false
    }
    handleClose = () => {
        console.log("handle close")
    }
    handleBarSeatSelect = () => {
        let state = this.state
        state.barSeatSelected = !this.state.barSeatSelected
        this.props.SelectionHandler(state)
        this.setState(state)
    }
    handleUnderageSelect = () => {
        let state = this.state
        state.underageSelected = !this.state.underageSelected
        this.props.SelectionHandler(state)

        this.setState(state)
    }
    handleWheelchairSelect = () => {
        let state = this.state
        state.wheelchairSelected = !this.state.wheelchairSelected
        this.props.SelectionHandler(state)
        this.setState(state)
    }
    handleShowHighchairModal = () => {
        this.setState({showHighChairModal: !this.state.showHighChairModal})    
    }
    handleShowBoosterModal = () => {
        this.setState({showBoosterModal: !this.state.showBoosterModal})    
    }

    handleHighChairDecrement = () => {
        if ((this.state.highChairNumber - 1) >= 0) {
            let state = this.state
            state.highChairNumber =  this.state.highChairNumber - 1
            this.props.SelectionHandler(state)
            this.setState(state)
        }
    }
    handleHighChairIncrement = () => {
        if ((this.state.highChairNumber + 1) <= 10) {
            let state = this.state
            state.highChairNumber =  this.state.highChairNumber + 1
            this.props.SelectionHandler(state)
            this.setState(state)
        }
    }

    handleBoosterDecrement = () => {
        if ((this.state.boosterNumber - 1) >= 0) {
            let state = this.state
            state.boosterNumber =  this.state.boosterNumber - 1
            this.props.SelectionHandler(state)
            this.setState(state)
        }
    }
    handleBoosterIncrement = () => {
        if ((this.state.boosterNumber + 1) <= 10) {
            let state = this.state
            state.boosterNumber =  this.state.boosterNumber + 1
            this.props.SelectionHandler(state)
            this.setState(state)
        }
    }

    handleButtonPress = () => {
        console.log("apply style")
    }
    handleButtonRelease = () => {
        console.log("remove style")
    }
    // check box, and button to open modal.
    render() {
        return (
            <div id="button-parent" style={{ justifyContent: "left", padding: "1px 20px 30px"}}>
                <Container>
                    <Row>
                        <div onClick={this.handleBarSeatSelect} 
                            className={ this.state.barSeatSelected ? 'button-selected' : "button-deselected"}>
                            <Container>
                                <Row>
                                    <Image src={process.env.PUBLIC_URL + `/assets/partyscreen/barSeat-45.png`} className="button-image" />
                                </Row>
                                <Row>
                                    <div className="button-text">Bar seat ok</div>
                                </Row>
                            </Container>
                        </div>
                        <div onClick={this.handleUnderageSelect} 
                            className={ this.state.underageSelected ? 'button-selected' : "button-deselected"}>
                            <Container>
                                <Row>
                                    <Image src={process.env.PUBLIC_URL + `/assets/partyscreen/under21-45.png`} className="button-image" />
                                </Row>
                                <Row>
                                    <div className="button-text">Underage</div>
                                </Row>
                            </Container>
                        </div>
                        <div onClick={this.handleWheelchairSelect} 
                            className={ this.state.wheelchairSelected ? 'button-selected' : "button-deselected"}>
                            <Container>
                                <Row>
                                    <Image src={process.env.PUBLIC_URL + `/assets/partyscreen/wheelchair-45.png`} className="button-image" />
                                </Row>
                                <Row>
                                    <div className="button-text">Wheelchair</div>
                                </Row>
                            </Container>
                        </div>

                        <div onClick={this.handleShowHighchairModal} 
                            className={ this.state.highChairNumber != 0 ? 'button-selected' : "button-deselected"}>
                            <Container>
                                <Row>
                                    <Image src={process.env.PUBLIC_URL + `/assets/partyscreen/highchair-45.png`} className="button-image" />
                                </Row>
                                <Row>
                                    <div className="button-text">High chair</div>
                                </Row>
                            </Container>
                        </div>

                        <div onClick={this.handleShowBoosterModal} 
                            className={ this.state.boosterNumber != 0 ? 'button-selected' : "button-deselected"}>
                            <Container>
                                <Row>
                                    <Image src={process.env.PUBLIC_URL + `/assets/partyscreen/booster-45.png`} className="button-image" />
                                </Row>
                                <Row>
                                    <div className="button-text">Booster</div>
                                </Row>
                            </Container>
                        </div>

                    </Row>
                </Container>
                
                <Modal show={this.state.showHighChairModal} onHide={this.handleClose}>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <div className="spacer">How many high chairs do you need?</div>
                            </Row>
                            <Row>
                                <div onClick={this.handleHighChairDecrement} 
                                    onTouchStart={this.handleButtonPress}
                                    onTouchEnd={this.handleButtonRelease}
                                    onMouseDown={this.handleButtonPress}
                                    onMouseUp={this.handleButtonRelease}
                                    id="button-style" className="spacer-left" style={{ marginRight: "10px"}}>-</div>
                                <div id="button-style-clear">
                                    {this.state.highChairNumber}
                                </div>
                                <div onClick={this.handleHighChairIncrement} id="button-style" className="spacer-right" style={{ marginLeft: "10px"}}>+</div>
                            </Row>
                            <Row>
                                <button id="red-button" onClick={this.handleShowHighchairModal} className="btn spacer">Done</button>
                            </Row>
                        </Container>
                    </Modal.Body>    
                </Modal>
                
                <Modal show={this.state.showBoosterModal} onHide={this.handleClose}>
                    <Modal.Body>
                        <Container >
                            <Row>
                                <div className="spacer">How many high chairs do you need?</div>
                            </Row>
                            <Row>
                                
                                <div onClick={this.handleBoosterDecrement} id="button-style" className="spacer-left" style={{ marginRight: "10px"}}>-</div>
                                <div id="button-style-clear">
                                    {this.state.boosterNumber}
                                </div>
                                <div onClick={this.handleBoosterIncrement} id="button-style" className="spacer-right" style={{ marginLeft: "10px"}}>+</div>
                            
                                
                            </Row>
                            <Row>
                                <button id="red-button" onClick={this.handleShowBoosterModal} className="btn spacer">Done</button>
                            </Row>
                        </Container>
                    </Modal.Body>    
                </Modal>
            </div>
        );
    }
}

export default SpecialRequests;