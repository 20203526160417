import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import RestaurantList from './components/RestaurantList';
import JoinWaitlist from './components/JoinWaitlist';
import ConfirmationPage from './components/ConfirmationPage';

function App() {
  return (
    <Router>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <div style={{ display: 'flex', borderBottom: '1px solid gray' }}>
          <a style={{ width: '100%' }} href="/"><img className="CakeWalkIcon" src="/assets/Group 2.svg"/></a>
          <a href="https://join.cakewalkapp.com/" className="CakeWalkLink">CakeWalk for restaurants</a>
        </div>
        <Switch>
          <Route exact path="/">
            <RestaurantList />
          </Route>
          <Route path="/join/:restaurantId" component={JoinWaitlist} />
          <Route path="/confirm/:restaurantId/:ticketId" component={ConfirmationPage} />
          <Route path="*">
            <RestaurantList />
          </Route>
        </Switch>
        </div>
    </Router>
  );
}

export default App;
