import React from "react";
import './Waitlist.css';

export interface QueueItem {
    size: number;
    phone: string;
    name: string;
    creationTime: string; 
}

interface WaitlistProps {
    position: number;
    queue: QueueItem[];
}

function getOrdinal(num: number): string {
    switch (num) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3: 
            return "rd";
    }
    return "th";
}

function timeSince(time: number): number
{
    return new Date((Date.now() - time)).getMinutes();
}

export class Waitlist extends React.Component<any> {
   render() {
       return (
        <div>
            <div style={{"color":"#828282", "textAlign":"center", "fontSize":"12px","marginBottom":"10px"}}>
                <span>You are </span><span style={{"color":"#488E50", "fontWeight":"bold"}}>{this.props.position+1}{getOrdinal(this.props.position+1)}</span><span> in line</span>
            </div>
            <div className="waitlistBox">
                <ol>       
                {this.props.queue.map((queueItem: any, idx: number) => {
                    if (idx == this.props.position) { // we can probably just switch on styles
                        return (
                            <li key={idx}>
                                <div style={{"color":"#333333", "fontSize":"12px","lineHeight":"14px","fontWeight":"bold"}}>{queueItem.party.name}, party of {queueItem.party.size}</div>
                                <div style={{"color":"#BDBDBD", "fontSize":"10px","lineHeight":"14px"}}>Joined {timeSince(Number(queueItem.creationTime))} mins ago</div>
                            </li>
                        )}
                    else
                    {
                        return <li key={idx} style={{"color":"#333333", "fontSize":"12px"}}>Party of {queueItem.party.size}</li>
                    }
                    })
                }
                </ol>
            </div>
        </div>
       );
   } 
}

export default Waitlist;